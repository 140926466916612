import { TRPCClientError } from '@trpc/client';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import superjson from 'superjson';
import type { InjectionKey, Plugin } from 'vue';

import type { AppRouter as LoopRouter } from '@grasp-gg/api-loop';

import { useAuthStore } from '@grasp-gg/extension-excel/store/auth';

export interface TrpcPluginOptions {
  loopHost: string;
}

const PUBLIC_AUTHORIZATION_HEADER = 'x-grasp-public';
const authorizationHeader: string = PUBLIC_AUTHORIZATION_HEADER;

const createClient = (options: TrpcPluginOptions) => ({
  loop: createTRPCProxyClient<LoopRouter>({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: `${options.loopHost}/trpc`,
        async headers() {
          if (authorizationHeader !== PUBLIC_AUTHORIZATION_HEADER) {
            return {
              Authorization: authorizationHeader,
            };
          }

          const accessToken = await useAuthStore().getAccessToken();

          return {
            Authorization: accessToken ?? PUBLIC_AUTHORIZATION_HEADER,
          };
        },
      }),
    ],
  }),
});

export function isTRPCClientError(
  cause: unknown,
  // biome-ignore lint/suspicious/noExplicitAny: import eslint
): cause is TRPCClientError<any> {
  return cause instanceof TRPCClientError;
}

export type Client = ReturnType<typeof createClient>;
export const CLIENT_INJECTION_KEY: InjectionKey<Client> = Symbol('trpc_client');

export const trpc = {
  install(app, options: TrpcPluginOptions) {
    app.provide(CLIENT_INJECTION_KEY, createClient(options));
  },
} satisfies Plugin;

export default trpc;
