import mixpanel from 'mixpanel-browser';
import type { Plugin } from 'vue';
import VueMixpanel from 'vue-mixpanel';

import sentry from '../services/sentry';

const isProduction = () => import.meta.env.MODE === 'prod';

const plugin: Plugin = (app) => {
  app.use(VueMixpanel, {
    token: '21d2f9099963ca685ef4eddee5d50833',
    config: {
      debug: !isProduction(),
      ignore_dnt: true,
    },
  });
};

export function trackEvent(
  event: string,
  properties?:
    | {
        [key: string]: unknown;
      }
    | undefined,
): void {
  if (!isProduction()) {
    return;
  }

  mixpanel.track(event, properties);

  sentry.addBreadcrumb({
    type: 'info',
    category: 'mixpanel',
    message: event,
    data: properties,
  });
}

export default plugin;
