import { split } from '@grasp-gg/extension-excel/utils/excel/range';

export function listWorksheetDataValidations(worksheetNameOrId: string) {
  return Excel.run((context) =>
    findDataValidations(
      context.workbook.worksheets.getItem(worksheetNameOrId).getUsedRange(),
    ),
  );
}

export async function findDataValidations(range: Excel.Range) {
  const validations = range
    .getSpecialCellsOrNullObject(Excel.SpecialCellType.dataValidations)
    .areas.load({
      dataValidation: {
        type: true,
        rule: true,
        prompt: true,
        errorAlert: true,
      },
      address: true,
      // to split the range if necessary
      columnCount: true,
      rowCount: true,
    });

  await range.context.sync();

  if (validations.isNullObject) return [];

  const validatedRanges = validations.items.flatMap((innerRange) => {
    // Excel `getSpecialCellsOrNullObject` groups by range contiguous cells
    // even when they don't have the same data validation value.
    // In this case, the `dataValidation` object will have the type `inconsistent`.
    // We want to iterate over the cells to have each variations.
    if (
      innerRange.dataValidation.type === Excel.DataValidationType.inconsistent
    ) {
      return listInconsistentDataValidation(innerRange);
    }

    return [
      {
        address: innerRange.address,
        dataValidation: innerRange.dataValidation,
      },
    ];
  });

  await range.context.sync();

  return validatedRanges;
}

/** List all data validation objects on a range where they are inconsistent */
function listInconsistentDataValidation(range: Excel.Range) {
  return split(range).map((cell) =>
    cell.load({
      dataValidation: {
        type: true,
        rule: true,
        prompt: true,
        errorAlert: true,
      },
      address: true,
    }),
  );
}
