import { asEmpty } from './utils';

const SHEET_NAME = import.meta.env.VITE_CACHING_SHEET_NAME;
const SHEET_VISIBILITY = import.meta.env.VITE_CACHING_SHEET_VISIBILITY;
const SHEET_PASSWORD = import.meta.env.VITE_CACHING_SHEET_PASSWORD || null;

export async function setup(options: {
  name: string;
}) {
  const worksheet = await initCachingSheet();
  const table = await initCachingTable(options);

  return {
    sheet: {
      name: worksheet.name,
      id: worksheet.id,
    },
    table: {
      name: table.name,
      id: table.id,
    },
  };
}

export function onTable<T>(
  nameOrId: string,
  fn: (options: {
    context: Excel.RequestContext;
    worksheet: Excel.Worksheet;
    table: Excel.Table;
  }) => Promise<T>,
) {
  return onSheet(async ({ context, worksheet }) => {
    const table = context.workbook.tables.getItem(nameOrId).load({
      name: true,
    });

    const result = await fn({
      context,
      worksheet,
      table,
    });

    return result;
  });
}

function onSheet<T>(
  fn: (options: {
    context: Excel.RequestContext;
    worksheet: Excel.Worksheet;
  }) => Promise<T>,
) {
  return Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getItem(SHEET_NAME);

    worksheet.visibility = 'Visible';
    SHEET_PASSWORD && worksheet.protection.unprotect(SHEET_PASSWORD);
    await context.sync();

    const result = await fn({
      context,
      worksheet,
    });

    worksheet.visibility = SHEET_VISIBILITY;
    SHEET_PASSWORD && worksheet.protection.protect({}, SHEET_PASSWORD);
    await context.sync();

    return result;
  });
}

function initCachingTable(options: { name: string }) {
  return onSheet(async ({ context, worksheet }) => {
    let table = context.workbook.tables.getItemOrNullObject(options.name).load({
      name: true,
      id: true,
    });

    await context.sync();

    if (table.isNullObject) {
      table = worksheet.tables.add('A1:A2', true).load();
      table.name = options.name;
      asEmpty(table);
    }

    await context.sync();

    return table;
  });
}

function initCachingSheet() {
  return Excel.run(async (context) => {
    let worksheet = context.workbook.worksheets
      .getItemOrNullObject(SHEET_NAME)
      .load({
        visibility: true,
        name: true,
        id: true,
      });

    await context.sync();

    if (worksheet.isNullObject) {
      worksheet = context.workbook.worksheets.add(SHEET_NAME).load({
        visibility: true,
        name: true,
        id: true,
      });
    }

    if (SHEET_PASSWORD) worksheet.protection.unprotect(SHEET_PASSWORD);
    worksheet.visibility = SHEET_VISIBILITY;
    if (SHEET_PASSWORD) worksheet.protection.protect({}, SHEET_PASSWORD);

    await context.sync();

    return worksheet;
  });
}
