/* eslint-disable complexity */
import type { TaxonomyField } from '@grasp-gg/lib-core-taxonomies';

import { isSignedFormula } from '@grasp-gg/extension-excel/services/taxo/field-validation/conditional-formatting/utils';
import { listTaxoFieldConditionalFormats } from '@grasp-gg/extension-excel/services/taxo/field-validation/list-taxo-field-conditional-formats';

import * as anything from './taxo-field-anything';
import * as checkbox from './taxo-field-checkbox';
import * as choice from './taxo-field-choice';
import * as date from './taxo-field-date';
import * as fixed from './taxo-field-fixed';
import * as number from './taxo-field-number';
import * as text from './taxo-field-text';
import * as url from './taxo-field-url';

export async function applyTaxoFieldConditionalFormattingAt(options: {
  field: TaxonomyField;
  at: Excel.Range;
}) {
  const formats = await listTaxoFieldConditionalFormats(
    options.at.worksheet.getRange(),
  );
  const expectedFormula = taxoFieldConditionalFormatting(options.field)
    ?.replaceAll('\n', '')
    ?.replaceAll(' ', '');

  if (!expectedFormula) {
    // unable to create the conditional formula
    return;
  }

  const matchingFormat = formats
    .map(
      (format) =>
        <const>[
          format,
          isSignedFormula(format.customOrNullObject.rule.formula),
        ],
    )
    .find(
      ([, result]) => result !== null && result.signature === options.field.id,
    );

  if (!matchingFormat) {
    const newFormat = options.at.conditionalFormats.add(
      Excel.ConditionalFormatType.custom,
    );

    newFormat.custom.rule.formula = expectedFormula;
    newFormat.custom.format.fill.color = '#FFC7CE';
    newFormat.custom.format.font.color = '#9C0006';

    await options.at.context.sync();
  } else {
    const [format] = matchingFormat;

    options.at.load({
      address: true,
      cellCount: true,
    });
    const initialRange = format.getRanges().load({ address: true });

    await initialRange.context.sync();

    // Currently there is no way to get the optimized union range.
    // https://github.com/OfficeDev/office-js/issues/4404
    // We check only a basic case where the exact address of the range
    // is already in the format range. This avoid duplicating the cell
    // in the range on multi-click on the apply button.
    if (
      !initialRange.address.includes(options.at.address) &&
      options.at.cellCount === 1
    ) {
      const targetRange = `${initialRange.address},${options.at.address}`;

      format.setRanges(options.at.worksheet.getRanges(targetRange));

      await initialRange.context.sync();
    } else {
      // The cell is already in the range, we don't need to do anything
      // to avoid duplicating the reference in the range.
    }

    // It's possible that the formula has been changed since the last time
    // .e.g. parameter change, fix in the formula, etc...
    if (format.customOrNullObject.rule.formula !== expectedFormula) {
      format.custom.rule.formula = expectedFormula;
      await format.context.sync();
    }
  }
}

export function isTaxoFieldConditionalFormatting(
  format: Excel.ConditionalFormat,
) {
  // TODO: improve
  return format.type === 'Custom';
}

export function taxoFieldConditionalFormatting(field: TaxonomyField) {
  if (field.type === 'choice') return choice.conditionFormula(field);
  else if (field.type === 'number') return number.conditionFormula(field);
  else if (field.type === 'checkbox') return checkbox.conditionFormula(field);
  else if (field.type === 'date') return date.conditionFormula(field);
  else if (field.type === 'fixed') return fixed.conditionFormula(field);
  else if (field.type === 'text') return text.conditionFormula(field);
  else if (field.type === 'url') return url.conditionFormula(field);
  else if (field.type === 'anything') return anything.conditionFormula(field);

  return null;
}
