import type { TaxonomyFieldUrl } from '@grasp-gg/lib-core-taxonomies';

import { asDataValidation } from '../validations/taxo-field-url-validation';
import { asSignedFormula } from './utils';

export function conditionFormula(field: TaxonomyFieldUrl): string {
  const {
    custom: { formula },
  } = asDataValidation(field);

  const withoutEqualSign = formula.slice(1);

  return asSignedFormula({
    signature: field.id,
    formula: `NOT(
      ${withoutEqualSign}
    )`,
  });
}
