import { isTaxoFieldConditionalFormatting } from '@grasp-gg/extension-excel/services/taxo/field-validation/conditional-formatting';

export async function listTaxoFieldConditionalFormats(
  range: Excel.RangeAreas | Excel.Range,
) {
  range.conditionalFormats.load({
    id: true,
    type: true,
    customOrNullObject: {
      rule: {
        formula: true,
      },
      format: {
        fill: { $all: true },
        font: { $all: true },
        borders: { $all: true },
      },
    },
  });

  await range.context.sync();

  return range.conditionalFormats.items.filter(
    isTaxoFieldConditionalFormatting,
  );
}
