import { computedAsync, tryOnMounted } from '@vueuse/core';
import type { MaybeRef } from 'vue';
import { toValue } from 'vue';
import { ref } from 'vue';

import { onExcelEvent } from './use-listener';

export interface Worksheet {
  name: string;
  id: string;
}

export const onWorksheetActivated = onExcelEvent(
  (context) => context.workbook.worksheets.onActivated,
);

export function useActiveWorksheetId() {
  const activeWorksheetId = ref<string | null>(null);

  onWorksheetActivated(async (event) => {
    activeWorksheetId.value = event.worksheetId;
  });

  tryOnMounted(async () =>
    Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet().load({
        id: true,
      });

      await context.sync();

      activeWorksheetId.value = worksheet.id;
    }),
  );

  return activeWorksheetId;
}

export function useWorksheet(worksheetNameOrId: MaybeRef<string | null>) {
  return computedAsync(
    () => {
      const value = toValue(worksheetNameOrId);

      return Excel.run(async (context) => {
        if (!value) return null;

        const worksheet = context.workbook.worksheets.getItem(value).load({
          name: true,
          id: true,
        });

        await context.sync();

        return worksheet;
      });
    },
    null,
    { lazy: true },
  );
}
