import type { TaxonomyFieldDate } from '@grasp-gg/lib-core-taxonomies';

import { asSignedFormula, self } from './utils';

export function conditionFormula(field: TaxonomyFieldDate): string {
  return asSignedFormula({
    signature: field.id,
    formula: `NOT(
      ISNUMBER(
        DAY(${self()})
      )
    )`,
  });
}
