import { type Plugin, inject } from 'vue';

import { setup as setupCachingTable } from '@grasp-gg/extension-excel/utils/excel/caching-table';

import { ensureTaxoValidationCacheSync } from './sync-cache-table';

const SYNCING_INJECTION_KEY = Symbol('taxo_fields_validation_syncing_state');

export const useTaxoFieldsValidationSync = () =>
  inject<string>(SYNCING_INJECTION_KEY)!;

export const taxoFieldValidationService = {
  async install(app) {
    const tableName = '_gg_txo_val_cache';

    await setupCachingTable({ name: tableName });

    const { isPending: isSyncing } = ensureTaxoValidationCacheSync();

    app.provide(SYNCING_INJECTION_KEY, isSyncing);
  },
} satisfies Plugin;
