import { computed, ref } from 'vue';

export function useAsync<TIn, TOut>(fn: (options: TIn) => Promise<TOut>) {
  type AsyncState = 'idle' | 'pending' | 'success' | 'error';

  const state = ref<AsyncState>('idle');

  return {
    state,
    isPending: computed(() => state.value === 'pending'),
    execute: async (options: TIn) => {
      state.value = 'pending';
      try {
        const result = await fn(options);

        state.value = 'success';

        return result;
      } catch (error) {
        state.value = 'error';

        throw error;
      }
    },
  };
}
