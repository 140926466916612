import { useQuery } from '@tanstack/vue-query';
import type { Pinia } from 'pinia';
import { computed } from 'vue';

import type { ListFieldsResponse } from '@grasp-gg/api-taxonomies';

import { useApiStore } from '../../../store/api';
import { useAuthStore } from '../../../store/auth';

export function useFields() {
  const authStore = useAuthStore();

  return useQuery({
    queryKey: ['taxo', 'fields'],
    queryFn: () =>
      listFields({
        organizationId: authStore.user!.organizationId,
      }),
    enabled: computed(() => !!authStore.user?.organizationId),
  });
}

export async function listFields(options: {
  organizationId: string;
  fieldIds?: string[];
  pinia?: Pinia;
}) {
  const apiStore = useApiStore(options.pinia);

  const params = new URLSearchParams({
    fieldIds: options.fieldIds?.length ? options.fieldIds.join(',') : '',
  });

  const response = await apiStore.requestApiTaxonomies<ListFieldsResponse>(
    `/organizations/${options.organizationId}/fields?${params}`,
    {
      method: 'get',
    },
  );

  if (!response?.success) throw response?.error;

  return response.fields ?? null;
}
