import { createEventHook } from '@vueuse/core';
import type { Ref } from 'vue';
import { onMounted, onUnmounted, ref } from 'vue';

export interface Worksheet {
  name: string;
  id: string;
}

export function useExcelListener<TEventPayload, TData extends object>(options: {
  event: (
    context: Excel.RequestContext,
  ) => OfficeExtension.EventHandlers<TEventPayload>;
  map: (
    context: Excel.RequestContext,
    payload: TEventPayload,
  ) => Promise<TData>;
  init?: (context: Excel.RequestContext) => Promise<TData>;
}) {
  const handler = ref<OfficeExtension.EventHandlerResult<TEventPayload> | null>(
    null,
  );
  const latest: Ref<TData | null> = ref(null);

  onMounted(async () => {
    await Excel.run<void>(async (context) => {
      const eventHandler = options.event(context);

      handler.value = eventHandler.add(async (event: TEventPayload) => {
        latest.value = await options.map(context, event);
      });

      if (options.init) latest.value = await options.init(context);
    });
  });

  onUnmounted(() => {
    handler.value?.remove();
  });

  return latest;
}

export const onExcelEvent =
  <TEventPayload>(
    event: (
      context: Excel.RequestContext,
    ) => OfficeExtension.EventHandlers<TEventPayload>,
  ) =>
  (handler: (event: TEventPayload) => Promise<void>) => {
    const [hook, removeExcelHandler] = createExcelHook({ event });

    // biome-ignore lint/suspicious/noExplicitAny: import eslint
    const { off } = hook.on(handler as any);

    return () => {
      off();
      removeExcelHandler();
    };
  };

export function createExcelHook<TEventPayload>(options: {
  event: (
    context: Excel.RequestContext,
  ) => OfficeExtension.EventHandlers<TEventPayload>;
}) {
  const excelHandler =
    ref<OfficeExtension.EventHandlerResult<TEventPayload> | null>(null);

  const hook = createEventHook<TEventPayload>();

  Excel.run(async (context) => {
    excelHandler.value = options.event(context).add(hook.trigger);
  }).catch((error) => {
    console.error(
      `Error during Excel event hook registration: ${error}`,
      error,
    );
  });

  const remove = () => excelHandler.value?.remove();

  return <const>[hook, remove];
}
