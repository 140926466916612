import type { NavigationGuard } from 'vue-router';

import { useAuthStore } from '../store/auth';

export const authenticationGuard = (async (to, _from, next) => {
  const authentication = useAuthStore();

  await authentication.tryToAuthenticate();

  const toPublic = Boolean(to.meta.public ?? false);
  const toLogin = to.name === 'Login';
  const toAuthentication = to.name === 'AuthenticationCallback';

  if (toAuthentication) {
    return next();
  }

  if (toLogin && authentication.isAuthenticated) {
    return next({ name: 'Home' });
  }

  if (!toPublic && !authentication.isAuthenticated) {
    return next({ name: 'Login' });
  }

  return next();
}) satisfies NavigationGuard;
