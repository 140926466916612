import type { TaxonomyFieldChoice } from '@grasp-gg/lib-core-taxonomies';

import { parameters } from '../validations/utils';
import { asSignedFormula, self } from './utils';

export function conditionFormula(field: TaxonomyFieldChoice): string {
  return asSignedFormula({
    signature: field.id,
    formula: `NOT(
      COUNTIF(
        ${parameters(field.id)},
        ${self()}
      ) > 0
    )`,
  });
}
