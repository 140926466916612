import { createRouter, createWebHistory } from 'vue-router';

import { authenticationGuard } from '@grasp-gg/extension-excel/routes/authentication.guard';

// Workaround to make sure vue-router is not using them
// https://github.com/vuejs/vue-router/issues/3154
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
delete window.history.pushState;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
delete window.history.replaceState;

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'Home',
      path: '/',
      redirect: '/selection',
      component: () => import('../views/Home.vue'),
      children: [
        {
          name: 'Selection',
          path: 'selection',
          component: () => import('../views/selection/Selection.vue'),
          children: [
            {
              name: 'SelectionContent',
              path: '',
              components: {
                taxoField: () =>
                  import('../views/selection/taxo-field/TaxoField.vue'),
                taxoTemplate: () =>
                  import('../views/selection/taxo-template/TaxoTemplate.vue'),
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Action',
      path: '/action',
      redirect: '/',
      component: () => import('../views/action/ActionLayout.vue'),
      children: [
        {
          name: 'Check Compliance',
          path: 'compliance',
          component: () => import('../views/action/compliance/Compliance.vue'),
        },
        {
          name: 'Check SDF Compliance',
          path: 'sdf',
          component: () => import('../views/action/sdf/Sdf.vue'),
        },
        {
          name: 'Upload Media Plan',
          path: 'upload-media-plan',
          component: () =>
            import('../views/action/upload-media-plan/UploadMediaPlan.vue'),
        },
        {
          name: 'Build Naming',
          path: 'build-naming',
          component: () =>
            import('../views/action/build-naming/BuildNaming.vue'),
        },
        {
          name: 'Link Taxonomy Field',
          path: 'as-taxo-field-value',
          component: () =>
            import('../views/action/as-taxo-field-value/AsTaxoFieldValue.vue'),
        },
        {
          name: 'Link Taxonomy Template',
          path: 'as-taxo-template',
          component: () =>
            import('../views/action/as-taxo-template/AsTaxoTemplate.vue'),
        },
      ],
    },
    {
      name: 'Dialog',
      path: '/dialog',
      children: [
        {
          name: 'MagicBuilder',
          path: 'magic-builder',
          component: () =>
            import('../views/dialog/magic-builder/MagicBuilder.vue'),
        },
        {
          name: 'AuthenticationCallback',
          path: 'auth',
          component: () =>
            import('../views/dialog/authentication/AuthenticationCallback.vue'),
        },
      ],
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('../views/Login.vue'),
      meta: {
        public: true,
      },
    },
  ],
});

router.beforeEach(authenticationGuard);
