import '@quasar/extras/material-icons/material-icons.css';
import { setCssVar } from 'quasar';
import { mapValues } from 'radash';
import '../styles/quasar.sass';

const themes = {
  default: {
    primary: '#2d62ff',
    secondary: '#133088',
    label: '#4e6094',
    background: '#f4f6fc',
    border: '#a9afcd',
    dark: '#133088',
    'dark-10': 'rgb(38 71 169 / 10%)',
    'dark-20': 'rgb(38 71 169 / 20%)',
    'dark-30': 'rgb(38 71 169 / 30%)',
    'dark-40': 'rgb(38 71 169 / 40%)',
    'dark-50': 'rgb(38 71 169 / 50%)',
    'dark-60': 'rgb(38 71 169 / 60%)',
    'dark-70': 'rgb(38 71 169 / 70%)',
    'dark-80': 'rgb(38 71 169 / 80%)',
    'dark-90': 'rgb(38 71 169 / 90%)',
    'red-5': 'rgb(241 62 83 / 5%)',
    'red-10': 'rgb(241 62 83 / 10%)',
    'red-20': 'rgb(241 62 83 / 20%)',
    'red-30': 'rgb(241 62 83 / 30%)',
    'red-40': 'rgb(241 62 83 / 40%)',
    'red-50': 'rgb(241 62 83 / 50%)',
    'red-60': 'rgb(241 62 83 / 60%)',
    'red-70': 'rgb(241 62 83 / 70%)',
    'red-80': 'rgb(241 62 83 / 80%)',
    'red-90': 'rgb(241 62 83 / 90%)',
    'green-5': 'rgb(78 180 73 / 5%)',
    'green-10': 'rgb(78 180 73 / 10%)',
    'green-20': 'rgb(78 180 73 / 20%)',
    'green-30': 'rgb(78 180 73 / 30%)',
    'green-40': 'rgb(78 180 73 / 40%)',
    'green-50': 'rgb(78 180 73 / 50%)',
    'green-60': 'rgb(78 180 73 / 60%)',
    'green-70': 'rgb(78 180 73 / 70%)',
    'green-80': 'rgb(78 180 73 / 80%)',
    'green-90': 'rgb(78 180 73 / 90%)',
    'shadow-color': '#2647A9 20',
  },
};

const common = {
  accent: '#9C27B0',
  green: '#1ec116',
  positive: '#1ec116',
  red: '#f13e53',
  negative: '#f13e53',
  info: '#31CCEC',
  warning: '#F2C037',
  'color-white': '#ffffff',
  'color-white-10': '#ffffff ab',
  'color-transparent-gray': '#f4f6fc 60',
  shadow: '1px 0px 18px 0px rgb(38 71 169 / 14%)',
};

export function setQuasarCssVar(): void {
  if (!window.localStorage?.getItem('vueuse-color-scheme')) {
    window.localStorage?.setItem('vueuse-color-scheme', 'light');
  }
  const theme = themes.default;

  mapValues(theme, (value, key) => setCssVar(key, value));
  mapValues(common, (value, key) => setCssVar(key, value));
}
