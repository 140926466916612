import { unique } from 'radash';
import { watchEffect } from 'vue';

import { useActiveWorksheetId } from '@grasp-gg/extension-excel/composables/excel/use-active-worksheet';
import { useAsync } from '@grasp-gg/extension-excel/composables/use-async';
import { listFields } from '@grasp-gg/extension-excel/services/api/taxonomies/use-fields';
import { cacheTaxoValidation } from '@grasp-gg/extension-excel/services/taxo/field-validation/apply-taxo-field-validation';
import { useAuthStore } from '@grasp-gg/extension-excel/store/auth';
import { nonNullish } from '@grasp-gg/extension-excel/utils';
import { listWorksheetDataValidations } from '@grasp-gg/extension-excel/utils/excel/data-validation';

import { isTaxoFieldValidation } from './serialization';

/** Listen for active sheet and sync its taxo fields */
export function ensureTaxoValidationCacheSync() {
  const worksheetId = useActiveWorksheetId();
  const authStore = useAuthStore();

  const { execute: sync, isPending } = useAsync(syncTaxoValidationCache);

  watchEffect(async () => {
    if (worksheetId.value && authStore.user?.organizationId)
      await sync({
        worksheetNameOrId: worksheetId.value,
        organizationId: authStore.user.organizationId,
      });
  });

  return {
    isPending,
  };
}

/** Sync cache table data with Grasp backend state */
export async function syncTaxoValidationCache(options: {
  worksheetNameOrId: string;
  organizationId: string;
}) {
  const validations = await listWorksheetDataValidations(
    options.worksheetNameOrId,
  );

  const taxoValidations = unique(
    validations
      .map((validation) => isTaxoFieldValidation(validation.dataValidation))
      .filter(nonNullish),
    (validation) => validation.fieldId,
  );

  const taxoFieldIds = taxoValidations.map((validation) => validation.fieldId);

  const taxoFields = await listFields({
    organizationId: options.organizationId,
    fieldIds: taxoFieldIds,
  });

  for (const field of taxoFields) {
    await cacheTaxoValidation({
      field,
    });
  }
}
