export interface SignedFormula {
  formula: string;
  signature: string;
}

export function asSignedFormula(options: SignedFormula): string {
  return `=IF(FALSE,"${options.signature}",${options.formula})`;
}

export function isSignedFormula(formula?: string): SignedFormula | null {
  const sourceRegex = new RegExp(
    '=IF\\(FALSE,"(?<signature>[^"]+)",(?<innerFormula>.*)\\)',
  );
  const result = formula?.match(sourceRegex);
  const signature = result?.groups?.signature ?? null;
  const innerFormula = result?.groups?.innerFormula ?? null;

  if (signature && innerFormula)
    return {
      formula: innerFormula,
      signature,
    };

  return null;
}

export function self() {
  return 'INDIRECT("RC", FALSE)';
}
