import {
  extendBottom,
  extendRight,
} from '@grasp-gg/extension-excel/utils/excel/range';

const EMPTY_HEADER = '_empty';

export async function ensureColumnExists(options: {
  columnName: string;
  table: Excel.Table;
}) {
  const headers = options.table.getHeaderRowRange();
  const exists = options.table
    .getHeaderRowRange()
    .findOrNullObject(options.columnName, {});

  await options.table.context.sync();

  if (await isEmpty(options.table)) {
    headers.values = [[options.columnName]];
  } else if (exists.isNullObject) {
    const tableRange = options.table.getRange();

    // insert the new column at the end of the table
    const newColumn = tableRange
      .getLastColumn()
      .getOffsetRange(0, 1)
      .insert(Excel.InsertShiftDirection.right)
      .load();

    const targetRange = await extendRight(tableRange, 1);

    options.table.resize(targetRange);
    newColumn.getCell(0, 0).values = [[options.columnName]];
  } else {
    // Column already exists, do nothing
  }

  await options.table.context.sync();

  return options.table
    .getHeaderRowRange()
    .findOrNullObject(options.columnName, {});
}

export async function ensureRowsCount(options: {
  count: number;
  table: Excel.Table;
}) {
  let body = options.table.getDataBodyRange().load({
    rowCount: true,
    rowIndex: true,
    columnIndex: true,
    columnCount: true,
  });

  await options.table.context.sync();

  if (body.rowCount < options.count) {
    const countToAdd = options.count - body.rowCount;
    const rowToAdd = body.getLastRow().getOffsetRange(1, 0);

    for (let i = 0; i < countToAdd; i++) {
      rowToAdd.insert(Excel.InsertShiftDirection.down);
    }

    options.table.resize(
      await extendBottom(options.table.getRange(), countToAdd),
    );

    await options.table.context.sync();
  } else {
    // Enough rows, do nothing
  }

  body = options.table.getDataBodyRange().load({
    rowCount: true,
  });

  await options.table.context.sync();

  return body.rowCount;
}

export async function columnRows(options: {
  name: string;
  table: Excel.Table;
}) {
  const headerRange = options.table
    .getHeaderRowRange()
    .findOrNullObject(options.name, {})
    .load({
      columnIndex: true,
    });
  const bodyRange = options.table.getDataBodyRange().load({
    rowCount: true,
    rowIndex: true,
  });

  await options.table.context.sync();

  if (headerRange.isNullObject) return null;

  return options.table.worksheet.getRangeByIndexes(
    bodyRange.rowIndex,
    headerRange.columnIndex,
    bodyRange.rowCount,
    1,
  );
}

export async function isEmpty(table: Excel.Table) {
  const headers = table.getHeaderRowRange().load({ values: true });

  await table.context.sync();

  return headers.values.length === 1 && headers.values[0][0] === EMPTY_HEADER;
}

export function asEmpty(table: Excel.Table) {
  const headers = table.getHeaderRowRange();

  headers.values = [[EMPTY_HEADER]];
}

export function emptyValues(count: number) {
  return Array.from({ length: count }, () => ['']);
}
